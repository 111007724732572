import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { resetPassword } from "@/api/userInfo";
import { hideLoading, showLoading, showToast } from "@/utils/utils";
export default defineComponent({
  name: "forgetPsw",

  setup() {
    const form = ref({
      phone: "",
      password: "",
      selectedCode: "+86",
      twoPassword: "",
      otp: "" // OTP

    });
    const router = useRouter();
    const errors = ref({
      phone: "",
      password: "",
      twoPassword: "",
      otp: ""
    });
    const showDropdown = ref(false);
    const countryCodes = ref([{
      text: "+86",
      value: "+86"
    }, {
      text: "+1",
      value: "+1"
    }, {
      text: "+44",
      value: "+44"
    }]);
    const isSending = ref(false);
    const countdown = ref(0);

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const selectCode = code => {
      form.value.selectedCode = code;
      showDropdown.value = false;
    };

    const startCountdown = () => {
      countdown.value = 60;
      const interval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    };

    const sendOtp = () => {
      if (countdown.value > 0 || isSending.value) return;
      isSending.value = true;
      setTimeout(() => {
        isSending.value = false;
        startCountdown();
      }, 2000);
    };

    const goBack = () => {
      router.back();
    };

    const validateForm = () => {
      errors.value.phone = form.value.phone ? "" : "Phone number is required";
      errors.value.password = form.value.password ? form.value.password.length >= 6 ? "" : "Password must be at least 6 characters" : "Password is required";
      return !errors.value.phone && !errors.value.password;
    };

    const onSubmit = async () => {
      if (validateForm()) {
        const fullPhone = form.value.selectedCode.replace("+", "") + form.value.phone;
        console.log("提交的数据:", fullPhone, form.value.password);

        try {
          showLoading("Processing..."); // 显示加载状态

          const res = await resetPassword({
            loginName: fullPhone,
            password: form.value.password
          });
          hideLoading(); // 请求完成后关闭加载状态

          if (res.code === 1) {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token");
            showToast("Password reset successful"); // 显示成功提示
            // 延迟跳转到首页

            setTimeout(() => {
              router.push("/login");
            }, 2000);
          } else {
            showToast(res.msg || "Error occurred while resetting password", 2000); // 显示错误提示
          }
        } catch (error) {
          hideLoading(); // 请求失败时关闭加载状态

          showToast("Password reset failed. Please try again later.", 2000); // 显示失败提示
        }
      } else {
        console.log("Validation failed, please check the form.");
      }
    };

    onMounted(() => {
      // 从本地存储中获取并解析 userInfo
      const userInfoString = localStorage.getItem("userInfo");
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

      if (userInfo && userInfo.userName) {
        form.value.phone = userInfo.userName; // 初始化 phone 字段
      }
    });
    return {
      form,
      errors,
      showDropdown,
      countryCodes,
      toggleDropdown,
      selectCode,
      sendOtp,
      isSending,
      countdown,
      onSubmit,
      goBack
    };
  }

});